import logo from './logo.svg';
import './App.css';
import TodoList from './Components/TodoList';
import TodoForm from './Components/TodoForm';
import TodoContextProvider from './Contexts/TodoContext';
import Counter from './Components/Counter'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <TodoContextProvider>
          <TodoList />
          <TodoForm />
        </TodoContextProvider>
        <Counter />
      </header>
    </div>
  );
}

export default App;